import { useRouter, BlitzPage } from "blitz"
import Layout from "app/core/layouts/Layout"
import { LoginForm } from "app/auth/components/LoginForm"
import { useEffect } from "react"
import { useCurrentUser } from "app/core/hooks/useCurrentUser"

const LoginPage: BlitzPage = () => {
  const router = useRouter()
  const user = useCurrentUser()

  useEffect(() => {
    if (user) {
      const next = router.query.next ? decodeURIComponent(router.query.next as string) : "/"
      router.push(next)
    }
  }, [user])

  return (
    <div>
      <LoginForm
        onSuccess={() => {
          const next = router.query.next ? decodeURIComponent(router.query.next as string) : "/"
          router.push(next)
        }}
      />
    </div>
  )
}

LoginPage.getLayout = (page) => <Layout title="Log In">{page}</Layout>

export default LoginPage
